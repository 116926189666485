import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"pb-0 mt-5 ml-2 mr-2",attrs:{"order":"1"}},[_c('Grid',{attrs:{"tamanho":12}},[_c(VAutocomplete,{attrs:{"dense":"","outlined":"","items":_vm.externasPainel,"label":"Perguntas","item-value":"id_pco_perguntas","item-text":"descricao","multiple":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c(VChip,[_c('span',[_vm._v(_vm._s(item.descricao))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.perguntas && _vm.perguntas.length - 1)+" outros)")]):_vm._e()]}}]),model:{value:(_vm.perguntas),callback:function ($$v) {_vm.perguntas=$$v},expression:"perguntas"}})],1),_c('Grid',{attrs:{"tamanho":12}},[_c('grafico-externas',{attrs:{"perguntas":_vm.perguntas}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }