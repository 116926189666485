<template>
  <div>
    <v-row class="pb-0 mt-5 ml-2 mr-2" order="1">
      <Grid :tamanho="12">
        <v-autocomplete
          dense
          outlined
          v-model="perguntas"
          :items="externasPainel"
          label="Perguntas"
          item-value="id_pco_perguntas"
          item-text="descricao"
          multiple
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item.descricao }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ perguntas && perguntas.length - 1 }} outros)</span
            >
          </template>
        </v-autocomplete>
      </Grid>
      <Grid :tamanho="12">
        <grafico-externas :perguntas="perguntas"></grafico-externas>
      </Grid>
    </v-row>
  </div>
</template>

<script>
import store from "@/store";
import { computed, ref } from "@vue/composition-api";

import Grid from "@/components/Grid.vue";
import GraficoExternas from "./externas/GraficoExternas.vue";
export default {
  components: {

    Grid,
    GraficoExternas,
  },
  setup() {
    const perguntas = ref([]);
    const externasPainel = computed(
      () => store.getters["pesquisas/getExternasPainel"]
    );

    function generateLabels(param) {
      let labels = [];
      labels = param.map((e) => {
        return e.descricao;
      });
      return labels;
    }
    function generateSeries(param) {
      let total = [];
      total = param.map((e) => {
        return e.total != undefined ? e.total : 0;
      });
      return total;
    }
    return {
      generateLabels,
      generateSeries,
      externasPainel,
      perguntas,
    };
  },
};
</script>

<style>
</style>