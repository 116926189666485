<template>
  <div>
    <v-row class="pb-0">
      <Grid
        :tamanho="12"
        v-for="(item, i) in perguntas"
        :key="item.id_pco_perguntas"
      >
        <v-card flat>
          <v-card-subtitle> {{ item.descricao }} </v-card-subtitle>
          <v-card-text class="d-flex align-center justify-center">
            <div class="w-50">
              <vue-apex-charts
                width="100%"
                :id="`chart-external-${i}`"
                :name="`chart-external-${i}`"
                :options="generateLabels(item.opcoes)"
                :series="generateSeries(item.opcoes)"
                class="d-flex flex-grow-1 align-center mb-4"
              ></vue-apex-charts>
            </div>
          </v-card-text>
        </v-card>
      </Grid>
    </v-row>
  </div>
</template>

<script>
import Grid from "@/components/Grid.vue";

import themeConfig from "@themeConfig";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    VueApexCharts,
    Grid,
  },
  props: {
    perguntas: {
      type: Array,
      required: false,
    },
  },
  setup(props) {
    const $chartColors = themeConfig.colors;

    const chartOptions = {
      colors: [
        $chartColors.yellow.variants.gradient1[0],
        $chartColors.yellow.variants.gradient1[1],
        $chartColors.yellow.variants.gradient1[2],
        $chartColors.yellow.variants.gradient1[3],
        $chartColors.yellow.variants.gradient1[4],
        $chartColors.yellow.variants.gradient1[5],
        $chartColors.yellow.variants.gradient1[6],
        $chartColors.yellow.variants.gradient1[7],
        $chartColors.yellow.variants.gradient1[8],
      ],
      chart: {
        type: "pie",
        toolbar: {
          show: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: true,
        position: "bottom",
        offsetY: 10,
      },
      labels: [],
    };

    function generateLabels(param) {
      let labels = [];
      labels = param.map((e) => {
        return e.descricao;
      });

      chartOptions.labels = labels;

      return chartOptions;
    }
    function generateSeries(param) {
      let total = [];
      total = param.map((e) => {
        return e.total != undefined ? e.total : 0;
      });
      return total;
    }
    return {
      generateLabels,
      generateSeries,
    };
  },
};
</script>

<style scoped>
.w-50 {
  width: 50% !important;
}
</style>
